import Vue from "vue";
import axios from "axios";
import { PHONE_CALL_STATUS, NOTIF_CALL_DURATION } from "@/common/const";

export default {
	namespaced: true,
	state: () => ({
		calls: {},
		timers:{},
		nextNotification: 0,
		durations: {},
		notifs: {}
	}),

	getters: {
		queued:(state) =>  Object.values(state.calls).filter(call => call.status === "queued"),
		inbound: (state) => Object.values(state.calls).filter(call => call.status === "ringing"),
		active: (state) => Object.values(state.calls).filter(call => call.status === "in-progress"),
		parked: (state) => Object.values(state.calls).filter(call => call.status === "parked").sort((a, b) => a.parkNo - b.parkNo),
		durations: (state) => state.durations
	},

	mutations: {
		setCall(state, call) {
			const callId = call.id;

			if (call.status === "completed") {
				this.commit("queue/releaseCall", callId);
				return;
			}

			if (state.timers[callId]) {
				clearInterval(state.timers[callId]);
				state.durations[callId];
				delete state.timers[callId];
			}

			const start = Math.ceil(Date.now()/1000) - ((call?.wait ? call.wait : call?.duration) || 2);

			Vue.set(state.calls, callId, call);
			Vue.set(state.durations, callId, Math.ceil(Date.now()/1000) - start);
			state.notifs[callId] = state.durations[callId] + NOTIF_CALL_DURATION;

			state.timers[callId] = setInterval(async () => {
				if (state.durations[callId]) {
					Vue.set(state.durations, callId, Math.ceil(Date.now()/1000) - start);
					if (state.durations[callId] >= state.notifs[callId]) {
						axios.get(`/voice/v1/calls/${callId}`)
							.then(({ data : call }) => {
								if (call.status === PHONE_CALL_STATUS.COMPLETED) {
									this.commit("queue/releaseCall", callId);
									return;
								}
								state.notifs[callId] += NOTIF_CALL_DURATION;
							})
							.catch(() =>this.commit("queue/releaseCall", callId));
					}
				}
			}, 1000);
		},

		releaseCall(state, callId) {
			if (state.calls[callId]) {
				console.debug(`[QUEUE] Releasing call ${callId}`);
				clearInterval(state.timers[callId]);
				Vue.delete(state.calls, callId);
				delete state.timers[callId];
				delete state.durations[callId];
				delete state.notifs[callId];
				console.debug("After release ", state.calls);
			}
		},

		releaseCalls(state) {
			console.debug("[QUEUE] Releasing calls");
			for (const callId of Object.keys(state.calls)) {
				this.commit("queue/releaseCall", callId);
			}
		}
	},

	actions: {
		setQueue({ commit, state }, calls) {
			try {
				if (calls.queued?.length) {
					for (const queue of calls.queued) {
						if (queue?.id) {
							commit("setCall", { ...queue, status:"queued" });
						}
					}
				}

				if (calls.actives?.length) {
					for (const active of calls.actives) {
						if (active?.id) {
							commit("setCall", active);
						}
					}
				}
				console.debug("[Calls] After loaded ", state.calls);
			} catch (error) {
				console.error("Error fetching queue", error);
			}
		},

		updateQueue({ commit }, call) {
			if (call?.id) {
				console.debug("call event ", call.status, " ", call.id);
				commit("setCall", call);
			}
		}
	}
};
