import {
	ALERT_HIDDEN,
	EMAILS_TAB,
	NOTES_TAB,
	SIGNATURE_OPTION,
	TEXTO_TAB
} from "@/common/const";
import { getField, updateField } from "vuex-map-fields";
import { insertSorted, getSortedPosition, updateConversationStateAttributes } from "@/common/utils";
import { Mutex } from "../common/mutex";

import { DISCONNECTED } from "@/composables/comms";
import axios from "axios";
import moment from "moment";
import { NOT_FOUND } from "http-status";
import { getConversation, getConversationsMap } from "@/composables/conversation";

const mutex = new Mutex();

export default {
	getters: {
		loadingAllConversations: state => {
			return state.loadingAllConversations;
		},

		currentGroup: state => {
			return state.currentGroup;
		},

		patient: state => {
			return state.patient;
		},

		hasPatient: state => {
			return state.hasPatient;
		},

		getConversation: (state) => (sid) => {
			return getConversation(sid);
		},

		hasConversation: state =>{
			return !!getConversationsMap().size;
		},

		averageRating: state => {
			return Math.round(state.averageRating);
		},

		surveyArray: state => {
			return state.surveyArray;
		},

		singleConversation: state => {
			return state.singleConversation;
		},

		actives : state => {
			return state.actives;
		},

		inactives : state => {
			return state.inactives;
		},

		pinned: state => {
			return state.pinned;
		},

		notes: state => {
			return state.notes;
		},

		messages: state => {
			return state.messages;
		},

		allMessages: state => {
			return state.allMessages;
		},

		isInitializing: state => {
			return state.isInitializing;
		},

		connectionState: state => {
			return state.connectionState;
		},

		hasUnreadSurveys: state => {
			return !!state.singleConversation?.channelState.attributes?.hasUnreadSurveys
				? state.hasUnreadSurveys && !!state.singleConversation?.channelState.attributes?.hasUnreadSurveys
				: !!state.singleConversation?.channelState?.attributes?.hasUnreadSurveys;
		},

		currentParticipant: state => {
			return state.currentParticipant;
		},

		isInboundMessage: rootGetters => message => {
			if (message?.state?.attributes?.isCall) {
				return message.state.attributes.direction === "inbound";
			}

			if (message?.state?.attributes?.type === "email") {
				return message.state.attributes.fromPatient;
			}

			return rootGetters["clinic/isFrance"]
				? message.direction == "inbound"
				: message?.author && /^\+\d+/i.test(message?.author);
		},

		hasNotifications: state => conversation => {
			return !!conversation?.channelState?.attributes?.hasNotification;
		},

		hasPendingNotifications: state => {
			const found = state.actives.some(sid =>
				getConversation(sid)?.channelState?.attributes?.hasNotification);

			if (found) {
				return true;
			}

			return state.inactives.some(sid =>
				getConversation(sid)?.channelState?.attributes?.hasNotification);
		},

		hasDisplayedConversation: state => {
			return !!state.singleConversation?.sid;
		},

		selectedAppointmentMessage: state => {
			return state.selectedAppointmentMessage;
		},

		contactAppointments: state => {
			return state.contactAppointments;
		},

		contactOldAppointments: state => {
			return state.contactOldAppointments;
		},

		contactPendingAppointments: state => {
			return state.contactPendingAppointments;
		},

		isTextoTab: state => {
			return state.activeSendBoxTab === TEXTO_TAB;
		},

		isEmailsTab: state => {
			return state.activeSendBoxTab === EMAILS_TAB;
		},

		isNotesTab: state => {
			return state.activeSendBoxTab === NOTES_TAB;
		},

		signatureOption: state => {
			return state.signatureOption;
		},

		prepareToCallLoader: state => {
			return state.prepareToCallLoader;
		},

		getField
	},
	mutations: {
		updateField,

		setLoadingAllConversations (state, loading) {
			state.loadingAllConversations = loading;
		},

		setCurrentGroup (state, currentGroup) {
			state.currentGroup = currentGroup;
		},

		setNewPatientDisplay(state, patient) {
			state.patient = patient;
		},

		setHasPatient(state, hasPatient) {
			state.hasPatient = hasPatient;
		},

		setAllConversation(state, { actives, inactives, pinned }) {
			state.singleConversation = {};

			state.actives = actives;
			state.inactives = inactives;

			this.commit("conversation/sortActiveConversations");
			this.commit("conversation/sortInactiveConversations");
		},

		sortActiveConversations(state) {
			state.actives = state.actives.sort((a, b) => {
				const conversationA = getConversation(a);
				const conversationB = getConversation(b);

				if (conversationA.channelState.attributes.pinned) {
					return -1;
				}

				if (conversationB.channelState.attributes.pinned) {
					return 1;
				}

				return conversationB?.dateUpdated - conversationA?.dateUpdated;
			});
		},

		sortInactiveConversations(state) {
			state.inactives = state.inactives.sort((a, b) => getConversation(b)?.dateUpdated - getConversation(a)?.dateUpdated);
		},

		async addConversation(state, conversation) {
			await mutex.lock();

			try {
				console.debug("addConversation ", conversation);

				if (conversation.state.current === "active") {
					insertSorted(state.actives, conversation);
				}
				else {
					insertSorted(state.inactives, conversation);
				}
			} finally {
				mutex.unlock();
			}
		},

		async addPinnedConversation(state, conversation) {
			try {
				insertSorted(state.pinned, conversation);
			} finally {
				mutex.unlock();
			}
		},

		async updateConversation(state, update) {
			await mutex.lock();

			try {
				const { conversation, updateReasons } = update;

				console.debug("updateConversation ", conversation.sid, " reasons ", updateReasons);

				if (updateReasons.includes("state")) {
					if (conversation.state.current === "active") {
						let idx = state.inactives.findIndex((sid) => sid === conversation.sid);

						if (idx > -1) {
							state.inactives.splice(idx, 1);
						}

						const pinnedCount = state.actives.reduce((total, current) => {
							if (current?.channelState?.attributes?.pinned) {
								return total + 1;
							}

							return total;
						}, 0);

						insertSorted(state.actives, conversation, false, pinnedCount);
					} else {
						let idx = state.actives.findIndex((sid) => sid === conversation.sid);

						if (idx > -1) {
							state.actives.splice(idx, 1);
						}

						insertSorted(state.inactives, conversation);
					}
				} else if (updateReasons.includes("dateUpdated")) {
					state.messages.sort((a, b) => new Date(a.state.timestamp) - new Date(b.state.timestamp));
					if (conversation.state.current === "active") {
						const pos = getSortedPosition(state.actives, conversation);

						if (state.actives?.[pos]?.sid !== conversation.sid) {
							this.commit("conversation/sortActiveConversations");
						}
					} else {
						const pos = getSortedPosition(state.inactives, conversation);

						if (state.inactives?.[pos]?.sid !== conversation.sid) {
							this.commit("conversation/sortInactiveConversations");
						}
					}
				}

			} finally {
				mutex.unlock();
			}
		},

		async removeConversation(state, conversation) {
			await mutex.lock();

			try {
				console.debug("removeConversation ", conversation.sid);

				if (state.singleConversation?.sid === conversation.sid) {
					this.commit("conversation/destroySingleConversation");
				}

				if (conversation.state.current === "active") {
					let idx = state.actives.findIndex((sid) => sid === conversation.sid);

					if (idx > -1) {
						state.actives.splice(idx, 1);
					}
				} else {
					let idx = state.inactives.findIndex((sid) => sid === conversation.sid);

					if (idx > -1) {
						state.inactives.splice(idx, 1);
					}
				}
			} finally {
				mutex.unlock();
			}
		},

		setNewConversationDisplay(state, sid) {
			console.debug("setNewConversationDisplay");
			const conv = getConversation(sid);

			if (!conv) {
				throw new Error(`Conversation sid ${sid} not found in the list`);
			}

			try {
				state.singleConversation = conv;

				conv.getMessages(15).then(messages =>{
					state.twilioMessagePage = messages;
					state.messages = messages.items.filter(message => !message.attributes.isFlowMessage);
					state.messages.sort((a, b) => new Date(a.state.timestamp) - new Date(b.state.timestamp));
					state.allMessages = messages.items;
				});

				if (state.singleConversation?.attributes?.hasIncompleteFollowup) {
					updateConversationStateAttributes(state.singleConversation?.sid, {
						attributes: {
							hasIncompleteFollowup: false
						}
					});
				}
			} catch (err) {
				console.error(`Exception occured in setNewConversationDisplay. ${err}`);
				throw err;
			}
		},

		addSingleMessage(state, message) {
			state.messages = [...state.messages, message];
		},

		setNotes(state, newNotes) {
			state.notes = newNotes;
		},

		setSurveys(state, surveys) {
			state.surveyArray = surveys;
		},

		setNewRating(state, avg) {
			state.averageRating = avg;
		},

		setNewMessage(state, isNew) {
			state.newMessage = isNew;
		},

		emptyStatesOnConversationSwitch(state) {
			state.notes = {};
			state.patient = {};
			state.hasPatient = false;
			state.surveyArray = [];
			state.averageRating = null;
			state.contactAppointments = [];
			state.contactOldAppointments = [];
			state.contactPendingAppointments = [];
			state.activeSendBoxTab = TEXTO_TAB;
		},

		destroySingleConversation(state) {
			if (state.singleConversation?.sid) {
				console.debug("destroySingleConversation");
				state.singleConversation = null;
				state.twilioMessagePage = undefined;
				state.messages = [];
				state.allMessages = [];
			}
		},

		setIsInitializing(state, isInitializing) {
			console.debug("setIsInitializing:", isInitializing);
			state.isInitializing = isInitializing;
		},

		setConnectionState(state, connectionState) {
			console.debug("setConnectionState:", connectionState);
			state.connectionState = connectionState;
		},

		setCurrentParticipant(state, userGroup) {
			state.currentParticipant = userGroup;
		},

		setSelectedAppointmentMessage(state, selectedAppointmentMessage) {
			state.selectedAppointmentMessage = selectedAppointmentMessage;
		},

		setContactAppointments(state, allAppointments) {
			state.contactAppointments = allAppointments;
		},

		setOldContactAppointments(state, oldAppointments) {
			state.contactOldAppointments = oldAppointments;
		},

		setPendingContactAppointments(state, pendingAppointments) {
			state.contactPendingAppointments = pendingAppointments;
		},

		setCurrentParticipantEmail(state, { email, emailProvider, valid }) {
			if (state.currentParticipant) {
				state.currentParticipant = {
					...state.currentParticipant,
					email,
					emailProvider,
					valid
				};
			}
		},

		setSignatureOption(state, option) {
			state.signatureOption = option;
		},

		setPrepareToCallLoader(state, value) {
			state.prepareToCallLoader = value;
		},

		setAudioNotificationsEnabled(state, value) {
			state.audioNotificationsEnabled = value;
		}
	},

	actions: {
		findByPhoneNumber({ state }, phoneNumber) {
			if (phoneNumber?.length) {
				let found = state.actives.find((sid) => getConversation(sid)?.friendlyName === phoneNumber);

				if (found) {
					return found;
				}

				return state.inactives.find((sid) => getConversation(sid)?.friendlyName === phoneNumber);
			}
		},

		async getAllNotes(context, id) {
			const { data: allNotes } = await axios.get(`/patients/${id}/notes`);

			allNotes.sort((a, b) => new Date(b.date) - new Date(a.date))
				.sort((a, b) => b.pin - a.pin);

			if (allNotes.length) {
				context.commit("setNotes", allNotes);
			}
		},

		async getAllSurveys(context, id) {
			const { data: allSurveys } = await axios.get(`/patients/${id}/surveys`);

			if (allSurveys.patientSurveys) {
				context.commit("setSurveys", allSurveys.patientSurveys);

				const unreadSurveyArray = allSurveys.patientSurveys.filter(survey => !survey.followupDone);

				if (unreadSurveyArray.length) context.state.hasUnreadSurveys = true;
			}
		},

		async getContactAppointments(context, id) {
			const { data: allAppointments } = await axios.get(`patients/${id}/appointments`);

			let oldAppointments = [];

			let pendingAppointments= [];

			allAppointments.map(appointment => {
				const appointmentDate = moment(appointment.start, "YYYY-MM-DD").format("YYYY-MM-DD");
				const currentDate = moment().format("YYYY-MM-DD");

				if (moment(appointmentDate).isBefore(currentDate)) oldAppointments.push(appointment);
				else pendingAppointments.push(appointment);
			});

			if (allAppointments.length) context.commit("setContactAppointments", allAppointments);
			if (oldAppointments.length) context.commit("setOldContactAppointments", oldAppointments);
			if (pendingAppointments.length) context.commit("setPendingContactAppointments", pendingAppointments);
		},

		async displayConversation({ state, commit, getters, dispatch }, sid) {
			if (state.loadingConversation) {
				console.debug("displayConversation is already loading a conversation. Skipped", sid);
				return;
			}

			try {
				state.loadingConversation = true;

				if (!sid?.length) {
					sid = state.actives?.[0] || state.inactives?.[0];
				}

				if (!sid || !getters.hasConversation || !getConversation(sid)) {
					commit("destroySingleConversation");
					return;
				}

				if (state.singleConversation?.sid) {
					commit("emptyStatesOnConversationSwitch");
				}

				commit("setNewConversationDisplay", sid);
				console.debug("displayConversation", sid);

				axios.get(`patients/${state.singleConversation?.friendlyName}`)
					.then(result => {
						const { data: patient } = result;

						commit("setNewPatientDisplay", patient);
						commit("setHasPatient", true);
						dispatch("getAllNotes", patient.id);
					}).catch(err => {
						if (err.response?.status === NOT_FOUND) {
							console.debug("Patient record not found");
							commit("setNewPatientDisplay", null);
							commit("setHasPatient", false);
							commit("setNotes", "");
						} else {
							throw err;
						}
					});
			} catch (err) {
				console.error(`Exception occured in displayConversation. ${err}`);
				throw err;
			} finally {
				state.loadingConversation = false;
			}
		},

		async fetchMoreMessages({ state }) {
			if (state.twilioMessagePage?.hasPrevPage) {
				const previousPage = await state.twilioMessagePage.prevPage();

				state.messages = [...previousPage.items, ...state.messages];
				state.twilioMessagePage = previousPage;
			}
		},

		async clearNotifications({ getters }, conversation) {
			updateConversationStateAttributes(conversation.sid, {
				attributes: {
					hasNotification: false,
					isAppointmentMicrosite: false,
					isInformationMicrosite: false
				}
			});

			console.debug("Cleared notification for conversation:", getters.singleConversation?.sid);
		},

		async pinConversation({ getters, state }, { conversation, pinned }) {
			updateConversationStateAttributes(conversation.sid, {
				attributes: {
					pinned: pinned
				}
			});

			let idx = state.actives.findIndex((sid) => sid === conversation.sid);

			if (idx > -1) {
				state.actives.splice(idx, 1);
			}

			const pinnedCount = state.actives.reduce((total, current) => {
				if (current?.channelState?.attributes?.pinned) {
					return total + 1;
				}

				return total;
			}, 0);

			insertSorted(state.actives, conversation, pinned, pinnedCount);

			console.debug("Pin/unpin conversation:", getters.singleConversation?.sid, pinned);
		},

		async markAsNotified({ getters }, conversation) {
			await axios.put(`conversations/${conversation.sid}`, {
				attributes: {
					hasNotification: true
				}
			});
			console.debug("Marked the conversation as unread:", conversation.sid);
		},

		async createConversationPayload({ rootGetters }, conversationPayload) {
			const { to, message } = conversationPayload;

			return {
				// When no from and identity sent. The main conversation group is used
				// from: twilioInfo.phoneNumber,
				// identity: twilioInfo.identity,
				to: to.replace(/\s/g, ""),
				clinicId: rootGetters["login/profile"].clinic,
				...message && {
					body: message
				}
			};
		},

		async markAppointmentMicrositeConfirmed({ state }) {
			if (state.selectedAppointmentMessage?.sid) {
				updateConversationStateAttributes(state.selectedAppointmentMessage.sid, {
					attributes: {
						isConfirmed: true
					}
				});
			}
		}
	},

	namespaced: true,
	state: () => ({
		actives: [],
		inactives: [],
		pinned: [],
		loadingAllConversations: false,
		singleConversation: {},
		patient: {},
		hasPatient: false,
		notes: [],
		messages: [],
		parentDialog: false,
		averageRating: null,
		surveyArray: [],
		followupCheckbox: false,
		allMessages: [],
		loadingConversation: false,
		twilioMessagePage: undefined,
		appointmentUI: false,
		appointmentIdModify: undefined,
		appointmentRequest: false,
		newMessage : false,
		hasUnreadSurveys : false,
		audioNotificationsEnabled : false,
		isInitializing: false,
		connectionState: DISCONNECTED,
		appointmentUpdate: false,
		selectedContactsTab: 0,
		currentParticipant: null,
		isAttachmentSent: false,
		openContactsDrawer: false,
		sendContentOpen: false,
		desktopNotificationsEnabled: false,
		browserNotificationsDisabled: false,
		showNotificationsAlert: ALERT_HIDDEN,
		toggleStatusLoading: false,
		signatureOption: SIGNATURE_OPTION.NO_SIGNATURE,
		isContactsSearch: false,
		awaitingConversationCreation: false,
		focusSendMessageInput: false,
		contactAppointments: [],
		contactOldAppointments: [],
		contactPendingAppointments: [],
		activeSendBoxTab: TEXTO_TAB,
		currentGroup: "",
		prepareToCallLoader: false
	})
};
