import * as Voice from "@twilio/voice-sdk";
import InboundCall from "./inbound-call";
import OutboundCall from "./outbound-call";
import axios from "axios";
import i18n from "../../i18n";
import store from "../../store";
import { validatePhoneForE164 } from "../../common/utils";
import { operateHeadset } from "@/common/headset";

let VoiceDevice = null;

const getAccessToken = async () => {
	console.debug("[CALL] getAccessToken");
	const { data: voiceToken } = await axios.post("/voice/v1/tokens", {
		identity: store.getters["login/profile"]?.id
	});

	return voiceToken.jwt;
};

const onInboundCall = async (call) => {
	console.debug("[INBOUND CALL] onInboundCall");

	if (VoiceDevice.isBusy || store.getters["voice/hasIncomingCall"]) {
		console.debug("[INBOUND CALL] call.reject()");
		call?.reject();
		return;
	}

	await operateHeadset(false, false, true, false);

	const inboundCall = InboundCall.from(call);

	console.debug("[CALL] setting up events");

	if (inboundCall.callDetails.fromQueue) {
		inboundCall.countDuration();
		inboundCall.accept();
		store.commit("voice/setInboundQueueCall", inboundCall);
	} else {
		store.commit("voice/setInboundCall", inboundCall);

		// Triggered when an incoming connection accepted
		inboundCall.callInstance.on("accept", () => {
			operateHeadset(true, false, false, false);
			inboundCall.countDuration();
			inboundCall.answered();
		});
	}

	// Triggered when the user cancelled the call without answering it
	inboundCall.callInstance.on("reject", async () => {
		await operateHeadset(false, false, false, false);
		store.dispatch("voice/endCall");
	});

	// Triggered any time a call is closed
	inboundCall.callInstance.on("disconnect", () => {
		operateHeadset(false, false, false, false);
		store.dispatch("voice/endCall");
	});

	inboundCall.callInstance.on("cancel", async () => {
		await operateHeadset(false, false, false, false);
		store.dispatch("voice/endCall");
	});
};

const outboundCall = async (phoneNumber, hasPatient) => {
	console.debug("[OUTBOUND CALL] outboundCall");

	if (!VoiceDevice) {
		console.error("VoiceDevice not yet initialized");
		return;
	}

	if (VoiceDevice.isBusy || store.getters["voice/hasIncomingCall"]) {
		console.debug("[OUTBOUND CALL] VoiceDevice.isBusy");
		return;
	}

	if (!validatePhoneForE164(phoneNumber)) {
		console.debug("[OUTBOUND CALL] INVALID PHONE");
		store.commit("alerts/add", {
			type: "error",
			message: i18n.t("error.phone-invalid"),
			timeout: false
		}, { root: true });
		return;
	}

	const params = {
		From: store.getters["conversation/currentParticipant"]?.phoneNumber,
		To: phoneNumber
	};

	const outboundCallInstance = await VoiceDevice.connect( { params });
	const outboundCall = OutboundCall.from(outboundCallInstance, params);

	store.commit("voice/setOutboundCall", outboundCall);

	outboundCall.callInstance.on("accept", async () => {
		console.debug("[OUTBOUND CALL] Accept");
		await operateHeadset(true, false, false, false);
	});

	outboundCall.callInstance.on("reject", () => {
		operateHeadset(false, false, false, false);
		store.dispatch("voice/endCall");
	});

	outboundCall.callInstance.on("disconnect", () => {
		operateHeadset(false, false, false, false);
		store.dispatch("voice/endCall");
	});

	outboundCall.callInstance.on("error", async (err) => {
		console.debug("[OUTBOUND CALL] Error");
		await operateHeadset(false, false, false, false);
		if (err?.name === "ConnectionError") {
			store.commit("alerts/add", {
				type: "error",
				message: i18n.t("error.call-error"),
				timeout: true
			}, { root: true });
		}
	});

	outboundCall.start(hasPatient);
	console.debug("[CALL] OUTBOUND CALL SETUP COMPLETE");
};

const pickQueuedCall = async (callSid) => {
	try {
		await axios.post("/voice/v1/queues/pick", {	callSid });
	} catch (err) {
		console.error("The call is no longer available:", err);
	}
};

const answerCall = async (callId) => {
	store.commit("voice/disconnectCall");
	axios.post("/voice/v1/calls/answer", { callId }).catch(err => console.error(err));
};

const rejectCall = async (callId) => {
	store.commit("voice/disconnectCall");
	axios.post("voice/v1/calls/reject", { callId }).catch(err => console.error(err));
};

const bootstrap = async () => {
	console.debug("[CALL] bootstrap");
	await cleanup();
	initVoiceDevice();
};

async function initVoiceDevice() {
	console.debug("[CALL] initVoiceDevice");

	if (VoiceDevice) {
		console.warn("VoiceDevice already initialized");
		return;
	}

	const accessToken = await getAccessToken();

	VoiceDevice = new Voice.Device(accessToken, {
		allowIncomingWhileBusy: false
	});

	console.debug(`[CALL] VoiceDevice: ${VoiceDevice}`);

	VoiceDevice.on("tokenWillExpire", async () => {
		console.debug("[CALL] tokenWillExpire");
		const newToken = await getAccessToken();

		VoiceDevice.updateToken(newToken);
	});

	VoiceDevice.on("registered", () => {
		console.debug("[CALL] registered");
		store.commit("voice/setReady", true);
	});

	VoiceDevice.on("unregistered", () => {
		console.debug("[CALL] unregistered");
		store.commit("voice/setReady", false);
	});

	VoiceDevice.on("incoming", onInboundCall);

	VoiceDevice.on("error", (err) => {
		console.debug("[CALL] error");
		console.error(err);
	});

	VoiceDevice.register();
	console.debug("[CALL] VOICE DEVICE REGISTERED");
}
const cleanup = async () => {
	console.debug("[CALL] cleanup");
	store.commit("voice/disableMicrophone");
	store.commit("voice/reset");
	VoiceDevice?.destroy();
	VoiceDevice = null;
};

const useVoice = {
	bootstrap,
	outboundCall,
	pickQueuedCall,
	cleanup,
	answerCall,
	rejectCall
};

export default useVoice;
