<template>
	<div>
		<support-dialog
			v-model="supportDialog"
			:full-path="`${apiUrl}${$route.fullPath}`"
			@closeDialog="supportDialog = false"
		/>

		<logout-dialog v-model="logoutDialog" />

		<v-navigation-drawer
			v-if="connected && profile.clinic"
			v-model="drawer"
			dark
			color="secondary"
			app
			permanent
			:temporary="!mini"
			expand-on-hover
			:mini-variant.sync="mini"
		>
			<div class="full-height d-flex flex-column">
				<v-list>
					<v-list-item>
						<v-list-item-icon class="mr-3">
							<v-img max-width="24" src="@/assets/img/icon-fresk-dark.svg" />
						</v-list-item-icon>

						<v-list-item-content class="d-flex">
							<v-list-item-title v-if="!mini" class="logo-image">
								<img max-width="100" src="@/assets/img/logo-white.svg" class="ml-3">
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider />

				<navigation-list
					:items="filterBySector(items.top)"
					class="flex-grow-1"
					:notification="hasPendingNotifications"
				/>

				<v-divider />

				<navigation-list
					:items="filterBySector(items.bottom)"
					@click:reportProblem="supportDialog = true"
					@click:logout="logoutUser"
					@click:helpCenter="goToHelpCenter"
					@click:supportTalk="callToSupportCenter"
				/>
			</div>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

import { createConversation, prodMode, updateConversationStateAttributes } from "@/common/utils";
import { CONSULTATION_WHITELIST, HelpCenterLink, SUPPORT_ID } from "@/common/const";
import { version } from "@/main";
import { API_URL } from "@/App.vue";

import SupportDialog from "../support/SupportDialog";
import LogoutDialog from "../login/LogoutDialog";

import NavigationList from "./NavigationList.vue";
import axios, { HttpStatusCode } from "axios";

export default {
	name: "NavigationMenu",
	components: { SupportDialog, LogoutDialog, NavigationList },

	data() {
		return {
			drawer: true,
			mini: true,
			supportDialog: false,
			logoutDialog: false,
			oldActiveType: undefined,
			version,
			apiUrl: API_URL,
			items: {
				top: [{
					id: "conversations",
					title: "conversations",
					icon: "mdi-message-text-outline",
					route: "/conversations"
				}, {
					id: "text-bloc",
					title: "quick-answers",
					icon: "mdi-message-plus-outline",
					route: "/text-bloc"
				}, {
					id: "library",
					title: "libraries",
					icon: "mdi-notebook-outline",
					route: "/material/2"
				}, {
					id: "consultations",
					title: "consultations",
					icon: "mdi-clipboard-check-outline",
					route: "/consultations",
					disabled: () => !CONSULTATION_WHITELIST.includes(this.profile.clinic)
				}, {
					id: "contacts",
					title: "contacts",
					icon: "mdi-account-group",
					route: "/contacts"
				}, {
					id: "configuration",
					title: "configuration",
					icon: "mdi-cog-outline",
					route: "/configuration"
				}],
				bottom: [{
					id: "devops",
					title: "devops",
					icon: "mdi-wrench",
					route: "/devops",
					visibilityGuard: () => this.isDevops
				}, {
					id: "help",
					title: "help",
					icon: "mdi-help-circle-outline",
					submenu: [
						{
							id: "helpCenter",
							title: "help-center",
							icon: "mdi-open-in-new"
						},
						{
							id: "reportProblem",
							title: "report-a-problem",
							icon: "mdi-alert"
						},
						{
							id: "supportTalk",
							title: "talk-with-the-support",
							icon: "mdi-face-agent"
						}
					]
				}, {
					id: "version",
					title: "version",
					titleParams: { version },
					subtitle: !prodMode() ? process.env.NODE_ENV : undefined,
					icon: "mdi-animation-outline",
					route: "/release-notes",
					visibilityGuard: () => !this.isFrance
				}, {
					id: "logout",
					title: "logout",
					icon: "mdi-logout"
				}]
			},
			supportId: SUPPORT_ID
		};
	},

	computed: {
		...mapGetters("login", ["connected", "profile", "isDevops"]),

		...mapGetters("conversation", [
			"singleConversation",
			"isInitializing",
			"hasPendingNotifications",
			"currentParticipant"
		]),

		...mapFields("blocGrid", ["activePostType", "loadingPosts"]),
		...mapGetters("clinic", ["isSector", "clinicData", "hasWebsite", "isFrance"])
	},

	watch: {
		mini() {
			this.$emit(this.mini ? "mini" : "expanded");
		}
	},

	methods: {
		...mapActions("conversation", ["displayConversation"]),

		async logoutUser() {
			this.logoutDialog = true;
		},

		filterBySector(menuItems) {
			if (!Array.isArray(menuItems)) return menuItems;

			return menuItems.filter((menuItem) => {
				return (
					!menuItem.visibilityGuard ||
					(typeof menuItem.visibilityGuard == "function" && menuItem.visibilityGuard())
				);
			});
		},

		changeNavigation(newLink) {
			if (this.oldActiveType !== undefined && this.oldActiveType != newLink) {
				this.loadingPosts = true;
				this.disableNavigation(newLink);
			}

			this.oldActiveType = newLink;
		},

		goToHelpCenter() {
			let link = "";

			switch (this.profile.locale) {
				case "fr-FR":
					link = HelpCenterLink.FR_FR;
					break;

				case "fr-CA":
					link = HelpCenterLink.FR_CA;
					break;

				case "en-CA":
					link = HelpCenterLink.EN_CA;
					break;
			}
			window.open(link, "_newtab");
		},

		async callToSupportCenter() {
			let isClinicContact = false;

			let supportPatient = null;

			try {
				supportPatient = (await axios.get(`/patients/${this.supportId}`)).data;

				if ((supportPatient.clinic).includes(this.profile.clinic)) {
					isClinicContact = true;
				}
			} catch (err) {
				console.debug("Can not retrieve support patient data as a clinic patient. It needs to be assigned as new.");
			}

			try {
				if (!isClinicContact) {
					supportPatient = (await axios.put(`/patients/${this.supportId}/${this.profile.clinic}`)).data;
				}
			} catch (err) {
				console.error("Cannot add support to clinic patients.");
			}

			if (supportPatient) {
				await this.goToSupportConversation(supportPatient);
			}
		},

		async goToSupportConversation(contact) {
			try {
				this.$store.commit("conversation/setPrepareToCallLoader", true);
				const sid = (await createConversation("conversations?init=true", {
					identity: this.currentParticipant.identity,
					from: this.currentParticipant.phoneNumber,
					to: contact?.id
				})).data.sid;

				contact.conversation = { sid };
				if (this.$route.name !== "conversations") {
					this.$router.push({ name: "conversations", params: { sid, call: false } });
				} else {
					// Temporary navigation to another route before returning to conversations.
					this.$router.push({ name: "/login" }).then(() => {
						this.$router.push({ name: "conversations", params: { sid, call: false } });
					});
				}
			} catch (err) {
				if (err.response?.status === HttpStatusCode.BadRequest) {
					this.$error(this.$t("error.add-conversation-number"));
				} else {
					this.$error(this.$t("error.add-conversation"));
				}
			}
		}
	}
};
</script>

<style scoped>
	.logo-image {
		max-width: 100px;
		display: flex;
		align-items: center;
	}
</style>
