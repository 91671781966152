import axios from "axios";
import store from "../store";
import router from "../router";
import { API_URL } from "../App.vue";

const LOGOUT_EVENT = "logout";

const routeExceptions = [
	"login",
	"widget"
];

export const handleLogoutEvent = async (ev) => {
	const currentRoute = router.app.$route.name;

	if (ev.key === LOGOUT_EVENT && ev.newValue) {
		// Ignore widget and login pages
		if (routeExceptions.some(route => currentRoute && currentRoute.indexOf(route) != -1)) {
			return;
		}

		await store.dispatch("login/logout");
	}
};

export const authenticate = async (username, password) => {
	const { data: { jwt, user, organization } } = await axios.post(`${API_URL}/auth/login`, {
		username,
		password
	});

	store.commit("login/setJwt", jwt);
	store.commit("login/setUserProfile", user);
	store.commit("clinic/setCurrent", organization);

	// If we have a cookie that indicates which sip phone is linked to this computer, use it !
	if ($cookies.isKey("linkedSipIdentity")) {
		const sipIdentity = $cookies.get("linkedSipIdentity");

		// Cleanup to avoid a user being assigned 2 identities simultaneously
		// Also allows extending lifetime of a cookie every connection
		await store.dispatch("sip/unAssignUser");
		store.dispatch("sip/assignUser", sipIdentity);
	} else {
		store.dispatch("sip/unAssignUser");
	}

	await store.dispatch("login/removeTypedMessages", { userId:user.id });
};
