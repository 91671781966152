import { getField } from "vuex-map-fields";
import axios from "axios";

export default {
	getters: {
		getField,
		associated: (state) => state.associated
	},

	actions: {
		async assignUser({ state }, sip) {
			const { data: assignedSip } = await axios.put(`/sips/v1/users/assign/${sip}`);

			state.associated = assignedSip;
			$cookies.set("linkedSipIdentity", sip);
		},

		async unAssignUser({ state }) {
			await axios.put("/sips/v1/users/unassign");
			state.associated = null;
			$cookies.remove("linkedSipIdentity");
		},

		assigned({ state }) {
			axios.get("/sips/v1/users/assigned")
				.then(({ data: sip }) => {
					state.associated = sip;
				})
				.catch(err => state.associated = undefined);
		}
	},
	namespaced: true,
	state: () => ({
		associated: undefined
	})
};
