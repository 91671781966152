import store from "../store";

let ignoreNextInputReport = false;

let holdCommandTimestamp = null;  // Timestamp of the last hold command

let muteCommandTimestamp = null;  // Timestamp of the last mute/unmute or microphone lift command

const handleInputReport = event => {
	const { data, reportId } = event;
	const value = data.getUint8(0);

	// Ignore this report id it is irrelevant to us.
	if (reportId === 200) return;

	console.debug(`Received input report: Value = ${value}, Report ID = ${reportId}`);

	let keypadValue = ((data.getUint8(0) & 0x80) >> 7) | ((data.getUint8(1) & 0x07) << 1);
	const activeCall = store.getters["voice/activeCall"];
	const isOnCall = store.getters["voice/isOnCall"];

	const now = Date.now();

	console.debug(`Active call: ${!!activeCall}, Is on call: ${isOnCall}, Keypad Value: ${keypadValue}`);
	console.debug(`Current timestamp: ${now}, Hold command timestamp: ${holdCommandTimestamp}, Mute command timestamp: ${muteCommandTimestamp}`);

	if (ignoreNextInputReport) {
		console.debug(`Ignoring input report due to ignore flag. Value: ${value}`);
		ignoreNextInputReport = false;
		return;
	}

	// Check if the value is a mute/unmute or microphone lift command and set the timestamp immediately
	if (value === 5) {
		muteCommandTimestamp = now;
		console.debug(`Mute/unmute or microphone lift command detected, setting mute command timestamp: ${muteCommandTimestamp}`);
	}
	// Check if the value is a hold command and set the timestamp immediately
	else if (value === 9 || value === 11) {
		holdCommandTimestamp = now;
		console.debug(`Hold command detected, setting hold command timestamp: ${holdCommandTimestamp}`);
	}

	console.debug(`Processing input report with value: ${value}, current timestamp: ${now}, hold command timestamp: ${holdCommandTimestamp}, mute command timestamp: ${muteCommandTimestamp}`);

	if ((holdCommandTimestamp && (now - holdCommandTimestamp < 2000)) || (muteCommandTimestamp && (now - muteCommandTimestamp < 2000))) {
		if (value === 1) {
			console.debug("Ignoring hang-up command due to recent hold or mute/unmute command.");
			return;
		}
	}

	switch (value) {
		case 1:
			if (isOnCall) {
				console.debug("Processing hang-up due to input report.");
				activeCall?.hangUp();
				store.commit("voice/disconnectCall");
				store.commit("voice/clearDigitsPressed");
				if (window.mediaStream) {
					window.mediaStream.getTracks().forEach(track => track.stop());
				}
			}
			break;
		case 2:
			console.debug("Processing accept call due to input report.");
			activeCall?.accept();
			break;
		case 5:
			console.debug("Processing mute/unmute or microphone lift due to input report.");
			// Add appropriate logic to handle mute/unmute or microphone lift
			muteCommandTimestamp = now;  // Record the timestamp of the mute/unmute or microphone lift command
			console.debug(`Mute/unmute or microphone lift command processed, timestamp updated: ${muteCommandTimestamp}`);
			break;
		case 9:
		case 11:
			if (activeCall) {
				if (activeCall.onHold) {
					console.debug("Resuming call from hold.");
					activeCall.resume();
					operateHeadset(true, false, false, false);  // Ensure headset state is updated
				} else {
					console.debug("Putting call on hold.");
					activeCall.hold();
					operateHeadset(false, false, false, true);  // Ensure headset state is updated
				}
				holdCommandTimestamp = now;  // Record the timestamp of the hold command
				console.debug(`Hold command processed, timestamp updated: ${holdCommandTimestamp}`);
			}
			break;
		default:
			console.debug(`Unhandled HookSwitch call state: ${value} or keypad value: ${keypadValue}.`);
			break;
	}
};

export async function addHeadsetEventListeners(headset) {
	if (headset) {
		try {
			await headset.open();
			console.debug("Headset device opened successfully.");

			headset.collections.forEach(collection => {
				collection.inputReports.forEach(inputReport => {
					if (collection.usage === 0x0005 && collection.usagePage === 0x000B) {
						store.commit("voice/setHeadsetDeviceReportId", inputReport.reportId);
						console.debug(`Report ID set for headset: ${inputReport.reportId}`);
					}
				});
			});

			headset.oninputreport = handleInputReport;
		} catch (err) {
			console.error(`Error setting up headset event listeners: ${err}`);
		}
	} else {
		console.error("Headset device not found.");
	}
}

/**
 * Operates the headset by sending control commands via WebHID.
 *
 * @param {boolean} hook - Set to true to go off-hook, false to go on-hook.
 * @param {boolean} mute - Set to true to mute the headset, false to unmute.
 * @param {boolean} ringing - Set to true to enable ringing, false to disable.
 * @param {boolean} holding - Set to true to hold the call, false to resume.
 */
export async function operateHeadset(hook = false, mute = false, ringing = false, holding = false) {
	const headset = store.getters["voice/headsetDevice"];
	const reportId = store.getters["voice/headsetDeviceReportId"];

	if (headset) {
		const value = new Uint8Array([
			(hook ? 1 : 0) |
            (mute ? 2 : 0) |
            (ringing ? 4 : 0) |
            (holding ? 8 : 0)
		]);

		try {
			console.debug(`Sending report: ${value}`);

			// Set flag to ignore the next input report if going off-hook
			if (hook) {
				ignoreNextInputReport = true;
			}

			await headset.sendReport(reportId, value);
		} catch (err) {
			console.debug(`Error sending headset command (report): ${err}`);
		}
	} else {
		console.debug("Headset device not found.");
	}
}
